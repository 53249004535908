<template>
  <DetailTemplate
    :custom-class="'invoice-detail detail-page'"
    v-if="getPermission('invoice:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <div class="d-flex px-0">
          <!-- <Status :status="customerActivated"></Status> -->
          <template v-if="dbInvoice && dbInvoice.status">
            <CustomStatus
              :status="dbInvoice.status"
              endpoint="invoice/status"
            ></CustomStatus>
          </template>
          <v-chip
            label
            color="#a3150b"
            text-color="white"
            class="font-weight-600 font-10"
            v-if="dbInvoice?.is_bad_debt == 1"
          >
            Bad Debt
          </v-chip>
          <!--   <v-chip
              v-if="dbInvoice?.visit_relation?.id"
              label
              color="orange"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Visit</v-chip
            > -->
        </div>
        <div class="mt-5 px-0">
          <table width="100%">
            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Invoice Information
              </th>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Invoice #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <div class="d-flex">
                  <v-chip
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white p-3"
                    small
                    >{{ dbInvoice.barcode }}
                  </v-chip>

                  <v-tooltip
                    v-if="dbInvoice?.recurring_count > 0"
                    top
                    content-class="custom-top-tooltip"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10 ml-1"
                        color="chip-custom-blue"
                        label
                        outlined
                      >
                        <span style="color: red">{{
                          dbInvoice?.recurring_cycles
                        }}</span>
                        /
                        <span style="color: green">{{
                          dbInvoice?.recurring_count
                        }}</span>
                      </v-chip>
                    </template>
                    <span>No of Recurring / Total Schedule</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
            <tr>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Type
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-chip
                  small
                  class="font-weight-600 custom-blue-border text-capitalize justify-center my-0 font-10"
                  color="chip-custom-blue"
                  label
                  outlined
                >
                  {{
                    dbInvoice?.billing_type
                      ? dbInvoice.billing_type.replaceAll("-", " ")
                      : ""
                  }}
                </v-chip>
              </td>
            </tr>
            <tr v-if="dbInvoice?.visit_relation?.id">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Visit #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <v-chip
                  label
                  color="cyan"
                  outlined
                  class="text-white"
                  v-on:click.prevent.stop="routeToVisit()"
                  small
                  >{{ dbInvoice?.visit_relation?.barcode }}
                </v-chip>
              </td>
            </tr>
            <tr
              v-if="
                dbInvoice &&
                dbInvoice.quotation_relation &&
                dbInvoice?.quotation_relation?.barcode
              "
            >
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Quotation #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <Barcode
                  dense
                  small
                  :barcode="dbInvoice?.quotation_relation?.barcode"
                  :id="dbInvoice?.quotation_relation?.id"
                  :route="'quotation.detail'"
                ></Barcode>
              </td>
            </tr>
            <tr v-if="dbInvoice?.milestone_relation?.barcode">
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-600"
                width="130"
              >
                Milestone #
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <Barcode
                  dense
                  small
                  :barcode="dbInvoice?.milestone_relation?.barcode"
                ></Barcode>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Reference #
              </td>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500 text-capitalize"
              >
                <template v-if="dbInvoice?.customer_ref">{{
                  dbInvoice?.customer_ref
                }}</template>
                <em class="text-muted" v-else>no reference</em>
              </td>
            </tr>

            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Payment due
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="dbInvoice?.payment_due_text">
                  {{ dbInvoice?.payment_due_text ?? "-" }}</template
                >
                <em class="text-muted" v-else>no Payment due</em>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Payable Amount
              </td>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500 orange--text"
              >
                {{ formatMoney(dbInvoice?.total) }}
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Paid Amount
              </td>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500 green--text"
              >
                {{ formatMoney(dbInvoice?.paid_amount) }}
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Balance Amount
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0 red--text">
                  {{ formatMoney(dbInvoice?.pending_amount) }}</label
                >
              </td>
            </tr>
            <tr v-if="dbInvoice.recurring_message">
              <td valign="top" class="py-1 font-size-18 font-weight-600">
                Schedule
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <label class="m-0">{{ dbInvoice.recurring_message }}</label>
              </td>
            </tr>

            <tr>
              <th
                colspan="2"
                class="grey lighten-4 font-size-16 color-custom-blue py-1"
              >
                Invoice Dates
              </th>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Invoice Date
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="dbInvoice?.date"
                  ><label class="m-0" v-if="dbInvoice?.date">
                    {{ formatDate(dbInvoice?.date) ?? "-" }}</label
                  ></template
                >
                <em v-else class="text-muted"> no Invoice Date</em>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Invoice Due Date
              </td>
              <td valign="middle" class="py-1 font-size-18 font-weight-500">
                <template v-if="dbInvoice?.due_date">
                  <label class="m-0 red--text" v-if="dbInvoice?.due_date">
                    {{ formatDate(dbInvoice?.due_date) ?? "-" }}</label
                  ></template
                >
                <em v-else class="text-muted">no Invoice Due Date</em>
              </td>
            </tr>
            <tr>
              <td valign="middle" class="py-1 font-size-18 font-weight-600">
                Invoice Sent Date
              </td>
              <td
                valign="middle"
                class="py-1 font-size-18 font-weight-500 orange--text"
              >
                <template v-if="dbInvoice?.sent_date">
                  <label class="m-0 orange--text" v-if="dbInvoice?.sent_date">
                    {{ formatDate(dbInvoice?.sent_date) ?? "-" }}</label
                  ></template
                >
                <em v-else class="text-muted">no Sent Date</em>
              </td>
            </tr>
            <tr v-if="dbInvoice?.cancel_remark">
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Cancelled Remark
              </th>
            </tr>
            <tr v-if="dbInvoice?.cancel_remark">
              <td colspan="2" valign="middle" class="py-1 font-size-18">
                {{ dbInvoice?.cancel_remark }}
              </td>
            </tr>
            <!--  <tr v-if="dbInvoice?.bad_debt_remark">
              <th
                colspan="2"
                class="grey lighten-4 font-size-19 color-custom-blue py-1"
              >
                Bad Debt Remark
              </th>
            </tr>
            <tr v-if="dbInvoice?.bad_debt_remark">
              <td colspan="2" valign="middle" class="py-1 font-size-18">
                {{ dbInvoice?.bad_debt_remark }}
              </td>
            </tr> -->
          </table>
        </div>
      </v-card>
    </template>
    <template v-slot:header-title>
      <span
        v-if="pageLoaded"
        class="color-custom-blue font-weight-700 font-size-20 mr-2"
        >{{ dbInvoice.title }}</span
      >
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template
          v-if="
            dbInvoice.status != 3 &&
            dbInvoice.status != 5 &&
            dbInvoice.recurring_batch
          "
        >
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="invoiceLoading"
                class="mx-2 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('cancel-series')"
              >
                <v-icon left>mdi-cancel</v-icon> Cancel Series
              </v-btn>
            </template>
            <span>Cancel Series</span>
          </v-tooltip>
        </template>
        <template
          v-if="
            (dbInvoice.status == 4 || dbInvoice.status == 2) &&
            dbInvoice.pending_amount
          "
        >
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="invoiceLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('payment')"
              >
                <v-icon left>mdi-currency-usd</v-icon> Payment
              </v-btn>
            </template>
            <span>Click here to add Payment</span>
          </v-tooltip>
        </template>
        <template
          v-if="
            (dbInvoice.status == 4 || dbInvoice.status == 2) &&
            dbInvoice.pending_amount
          "
        >
          <v-tooltip bottom content-class="custom-bottom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                :disabled="invoiceLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('credit-note')"
              >
                <v-icon left>mdi-currency-usd</v-icon> Credit Note
              </v-btn>
            </template>
            <span>Click here to add Credit Note</span>
          </v-tooltip>
        </template>
        <v-btn
          v-if="dbInvoice?.status == 1 || dbInvoice?.status == 4"
          v-on:click="updateMoreAction('edit')"
          depressed
          color="cyan white--text"
          class="custom-bold-button"
          ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
        >
        <template
          v-if="dbInvoice?.more_actions && dbInvoice?.more_actions?.length"
        >
          <template>
            <v-menu
              v-if="dbInvoice?.more_actions && dbInvoice?.more_actions?.length"
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button mx-2"
                  >More... <v-icon small right>mdi-chevron-down</v-icon></v-btn
                >
              </template>
              <v-list>
                <template v-for="(more, index) in dbInvoice?.more_actions">
                  <v-list-item
                    link
                    v-on:click="updateMoreAction(more.action)"
                    :key="index"
                    :disabled="more.disabled"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
          <!--  <template v-else>
                <v-btn
                  v-for="(more, index) in dbInvoice?.more_actions"
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button mx-2"
                  ><v-icon small left>{{ more.icon }}</v-icon>
                  {{ more.title }}</v-btn
                >
              </template> -->
        </template>
        <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
          ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
        >
      </div>
    </template>
    <template v-slot:body>
      <!--  <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll quotation-detail-height"
          style="position: relative"
        > -->
      <v-container fluid v-if="pageLoaded">
        <!--  <v-row v-if="pageLoaded">
              <v-col md="12"> -->
        <v-tabs
          active-class="custom-tab-active"
          v-model="invoiceTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#payment">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Payments
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#credit-notes">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Credit Notes
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#attachment">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
              <!--end::Svg Icon-->
            </span>
            Attachments
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="invoiceTab">
          <v-tab-item value="overview">
            <v-container fluid>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-0">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >Location Detail</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <span class="color-custom-blue font-weight-700 font-size-19">
                    <router-link
                      :to="
                        getDefaultRoute('customer.detail', {
                          params: {
                            id: dbInvoice?.customer_relation?.id,
                          },
                        })
                      "
                      >{{ dbInvoice?.customer_relation?.display_name }}
                    </router-link>
                  </span>
                  <v-layout class="my-4">
                    <v-flex md6 class="mr-2 custom-border-right">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Site Location</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <!--   <a
                                v-if="
                                  dbInvoice?.property_relation?.id
                                "
                                target="_blank"
                                :href="`https://maps.google.com/?q=${dbInvoice?.property_relation?.property_address}`"
                                >{{ dbInvoice?.property_relation?.property_address }}</a
                              > -->

                            <template>
                              <div
                                class="custom-flex-d"
                                v-if="
                                  dbInvoice?.property_relation?.property_name
                                "
                              >
                                <b>
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#24326d !important"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                <span
                                  >{{
                                    dbInvoice?.property_relation?.property_name
                                  }}<br
                                /></span>
                              </div>
                              <div v-else>
                                <em class="text-muted">
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#24326d !important"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                  no Site Name</em
                                >
                              </div>
                            </template>

                            <template>
                              <div class="custom-flex-d">
                                <b>
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#24326d !important"
                                  >
                                    mdi-map-marker
                                  </v-icon>
                                </b>
                                <span>
                                  <template
                                    v-if="dbInvoice?.property_relation?.unit_no"
                                    >{{
                                      dbInvoice?.property_relation?.unit_no
                                    }},</template
                                  >
                                  <template
                                    v-if="
                                      dbInvoice?.property_relation?.street_1
                                    "
                                    >{{
                                      dbInvoice?.property_relation?.street_1
                                    }},</template
                                  >
                                  {{ dbInvoice?.property_relation?.street_2 }}
                                  <br />
                                  {{ dbInvoice?.property_relation?.country }}
                                  <template
                                    v-if="
                                      dbInvoice?.property_relation?.zip_code &&
                                      dbInvoice?.property_relation?.zip_code !=
                                        '000000'
                                    "
                                  >
                                    {{ dbInvoice?.property_relation?.zip_code }}
                                  </template>
                                </span>
                              </div>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="dbInvoice?.property_contact_person_relation?.id"
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              dbInvoice?.property_contact_person_relation
                                ?.display_name
                            }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            dbInvoice?.property_contact_person_relation
                              ?.primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              dbInvoice?.property_contact_person_relation
                                ?.primary_phone
                            }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            dbInvoice?.property_contact_person_relation
                              ?.primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              dbInvoice?.property_contact_person_relation
                                ?.primary_email
                            }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                    <v-flex md6 class="ml-2">
                      <table width="100%">
                        <tr>
                          <td class="font-weight-600">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Billing Address</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td class="py-0 font-size-18 font-weight-500">
                            <template>
                              <div
                                class="custom-flex-d"
                                v-if="
                                  dbInvoice?.billing_relation?.property_name
                                "
                              >
                                <b>
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#24326d !important"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                </b>
                                <span
                                  >{{
                                    dbInvoice?.billing_relation?.property_name
                                  }}<br
                                /></span>
                              </div>
                              <div v-else>
                                <em class="text-muted">
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#24326d !important"
                                    class="cmt-2"
                                  >
                                    mdi-home-map-marker
                                  </v-icon>
                                  no Site Name</em
                                >
                              </div>
                            </template>

                            <template>
                              <div class="custom-flex-d">
                                <b>
                                  <v-icon
                                    style="font-size: 18px; margin-top: -3px"
                                    color="#24326d !important"
                                  >
                                    mdi-map-marker
                                  </v-icon>
                                </b>
                                <span>
                                  <template
                                    v-if="dbInvoice?.billing_relation?.unit_no"
                                    >{{
                                      dbInvoice?.billing_relation?.unit_no
                                    }},</template
                                  >
                                  <template
                                    v-if="dbInvoice?.billing_relation?.street_1"
                                    >{{
                                      dbInvoice?.billing_relation?.street_1
                                    }},</template
                                  >
                                  {{ dbInvoice?.billing_relation?.street_2 }}
                                  <br />
                                  {{ dbInvoice?.billing_relation?.country }}
                                  <template
                                    v-if="
                                      dbInvoice?.billing_relation?.zip_code &&
                                      dbInvoice?.billing_relation?.zip_code !=
                                        '000000'
                                    "
                                  >
                                    {{ dbInvoice?.billing_relation?.zip_code }}
                                  </template>
                                </span>
                              </div>
                            </template>
                            <!-- <a
                                v-if="
                                 dbInvoice?.billing_relation?.id
                                "
                                target="_blank"
                                :href="`https://maps.google.com/?q=${dbInvoice?.billing_relation?.property_address}`"
                                >{{ dbInvoice?.billing_relation?.property_address }}</a
                              > -->
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Contact details</td>
                        </tr>
                        <tr
                          v-if="dbInvoice?.billing_contact_person_relation?.id"
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              dbInvoice?.billing_contact_person_relation
                                ?.display_name
                            }}
                          </td>
                        </tr>

                        <tr
                          v-if="
                            dbInvoice?.billing_contact_person_relation
                              ?.primary_phone
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              dbInvoice?.billing_contact_person_relation
                                ?.primary_phone
                            }}
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no phone number</em>
                        </tr>
                        <tr
                          v-if="
                            dbInvoice?.billing_contact_person_relation
                              ?.primary_email
                          "
                        >
                          <td class="py-0 font-size-18 font-weight-500">
                            {{
                              dbInvoice?.billing_contact_person_relation
                                ?.primary_email
                            }}
                            <!-- <v-icon
                            v-if="detail.billing_person_notify"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                            content="Notification was sent to given email"
                            color="green lighten-1"
                            >mdi-check</v-icon
                          > -->
                          </td>
                        </tr>
                        <tr v-else class="py-0 font-size-18 font-weight-500">
                          <em class="text-muted ml-2"> no email address</em>
                        </tr>
                      </table>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-0">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >DESCRIPTION</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class=""
                      more-str="read more"
                      :text="
                        dbInvoice?.description || '<em>No Description</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-0">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >INTERNAL NOTES</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <v-layout class="mb-4">
                    <v-flex md6 class="custom-border-right">
                      <label class="font-size-16 font-weight-600"
                        >Internal Note</label
                      >
                      <read-more
                        class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          dbInvoice?.admin_note || '<em> No Internal Note</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                    <v-flex md6>
                      <label class="font-size-16 font-weight-600"
                        >Client Note
                      </label>
                      <read-more
                        class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                        more-str="read more"
                        :text="
                          dbInvoice?.client_note || '<em> No Client Note</em>'
                        "
                        link="#"
                        less-str="read less"
                        :max-chars="200"
                      >
                      </read-more>
                    </v-flex>
                  </v-layout>
                  <v-layout
                    class="mt-2"
                    v-if="dbInvoice.attachments?.length && false"
                  >
                    <v-flex md12>
                      <label class="font-size-16 font-weight-600"
                        >Attachments ({{
                          dbInvoice.attachments?.length
                        }})</label
                      >
                      <table width="100%" class="custom-border-top-1px">
                        <tbody>
                          <tr
                            v-for="(row, index) in dbInvoice.attachments"
                            :key="`view-attach-${index}`"
                          >
                            <td valign="middle" width="80%" class="pr-2 py-2">
                              {{ row.name }}
                            </td>
                            <td
                              valign="middle"
                              width="20%"
                              align="right"
                              class="pl-2 py-2"
                            >
                              <v-icon color="cyan" class="mr-2"
                                >mdi-download</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4 py-0">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >TERMS &amp; CONDITIONS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <!--  <div
                      class="w-100 custom-grey-border p-4 mb-2"
                      v-if="
                        detail &&
                        detail.term_condition_id &&
                        detail.term_conditions_title &&
                        detail.term_conditions_title.label
                      "
                    >
                      {{ detail.term_conditions_title.label }}
                    </div> -->
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class="mx-2 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                      more-str="read more"
                      :text="
                        dbInvoice?.term_conditions ||
                        '<em>No Terms &amp; Conditions</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat class="custom-grey-border remove-border-radius mb-4">
                <v-card-title class="headline grey lighten-4">
                  <span
                    class="font-weight-700 custom-headline color-custom-blue font-size-16"
                    >PAYMENT TERMS</span
                  >
                </v-card-title>
                <v-card-text class="p-6 font-size-16">
                  <div class="w-100 custom-grey-border p-4">
                    <read-more
                      class="mx-2 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                      more-str="read more"
                      :text="
                        dbInvoice?.payment_terms ||
                        '<em>No Terms &amp; Conditions</em>'
                      "
                      link="#"
                      less-str="read less"
                      :max-chars="200"
                    >
                    </read-more>
                  </div>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item value="line-item">
            <line-item-view
              :show-price="dbInvoice.show_price"
              :discount-level="
                dbInvoice.discount_type == 2 ? 'line_item' : 'transaction'
              "
              :related-id="invoiceId"
              :related-type="2"
              is-invoice
            />
            <div class="mt-4 pr-2 font-weight-600">
              <table width="100%">
                <tr>
                  <td width="70%"></td>
                  <td width="15%">Sub Total</td>
                  <td width="15%" align="right">
                    {{ formatMoney(dbInvoice.sub_total) }}
                  </td>
                </tr>
                <tr v-if="dbInvoice.discount_type == 1">
                  <td></td>
                  <td>
                    Discount
                    <span v-if="dbInvoice.discount_value_type == 1"
                      >({{ formatMoney(dbInvoice.discount_value) }})</span
                    >
                    <span v-if="dbInvoice.discount_value_type == 2"
                      >({{ formatNumber(dbInvoice.discount_value) }}%)</span
                    >
                  </td>
                  <td align="right">
                    {{ formatMoney(dbInvoice.discount_amount) }}
                  </td>
                </tr>
                <tr v-if="dbInvoice.tax_applied">
                  <td></td>
                  <td>Tax [GST {{ dbInvoice.tax_value }}%]</td>
                  <td align="right">
                    {{ formatMoney(dbInvoice.tax_amount) }}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Adjustment</td>
                  <td align="right">
                    {{ formatMoney(dbInvoice.adjustment) }}
                  </td>
                </tr>
                <tr class="font-size-19">
                  <td></td>
                  <td>Grand Total</td>
                  <td align="right">{{ formatMoney(dbInvoice.total) }}</td>
                </tr>
              </table>
            </div>
          </v-tab-item>
          <v-tab-item value="payment">
            <InternalPayment :type-id="dbInvoice?.id"></InternalPayment>
          </v-tab-item>
          <v-tab-item value="credit-notes">
            <InternalPaymentCredit
              :type-id="dbInvoice?.id"
            ></InternalPaymentCredit>
          </v-tab-item>
          <v-tab-item value="visit"
            >'
            <VisitInternalAll
              :detail="dbInvoice"
              type="invoice"
              :invoice-id="invoiceId"
            ></VisitInternalAll
            >'
          </v-tab-item>

          <v-tab-item :value="'attachment'">
            <AdditionalDocuments
              :type-id="dbInvoice?.id"
              class="mx-0 mb-3"
              type-text="Invoice Attachments"
              :type="3"
              :extensive="false"
            ></AdditionalDocuments>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <internal-history-detail type="invoice" :type_id="dbInvoice?.id" />
          </v-tab-item>
        </v-tabs-items>
        <!-- </v-col>
            </v-row> -->
      </v-container>
      <!--  </perfect-scrollbar> -->
      <confirm-dialog
        key="confirm-dialog-invoice"
        :common-dialog="confirmDialog"
        :dialog-width="dialogWidth"
      >
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-container class="px-10" fluid>
            <v-form
              ref="invoiceStausForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="confirmSubmit()"
            >
              <p
                class="font-weight-600 font-size-19"
                v-if="confirmType != 'mark_as_send'"
              >
                Are you sure, you want to
                <span class="font-weight-700">{{ getConfirmTitle() }}</span
                >?
              </p>
              <p v-else class="font-weight-600 font-size-19">
                Are you sure you have
                <span class="font-weight-700"
                  >send out the invoice..... already to customer</span
                >?
              </p>
              <v-layout
                class="my-4"
                v-if="
                  confirmType == 'mark_as_approved' ||
                  confirmType == 'mark_as_rejected' ||
                  confirmType == 'mark_as_accepted' ||
                  confirmType == 'mark_as_cancelled' ||
                  confirmType == 'mark_as_bad_debt' ||
                  confirmType == 'cancel-series'
                "
              >
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="actionData.remark"
                    :rules="[
                      validateRules.required(actionData.remark, 'Remark'),
                    ]"
                    placeholder="Remark"
                    solo
                    flat
                    row-height="25"
                    counter="150"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-if="confirmType == 'mark_as_accepted'">
                <v-flex md12>
                  <label class="font-weight-500 font-size-16"
                    >Attachments (Max 5)</label
                  >
                  <table width="100%">
                    <tbody>
                      <tr
                        v-for="(row, index) in actionData.attachments"
                        :key="index"
                      >
                        <td width="50%" class="pl-0 pr-2 py-2">
                          <v-file-input
                            prepend-inner-icon="mdi-attachment mdi-rotate-90"
                            placeholder="Click here to select file"
                            label="Click here to select file"
                            prepend-icon=""
                            color="cyan"
                            solo
                            flat
                            v-model="row.accepted_file"
                            dense
                            v-on:change="updateFileName(index)"
                            hide-details
                          ></v-file-input>
                        </td>
                        <td width="50%" class="pr-0 pl-2 py-2">
                          <v-text-field
                            placeholder="File Name"
                            label="File Name"
                            dense
                            solo
                            flat
                            color="cyan"
                            v-model="row.file_name"
                            hide-details
                            class="has-delete-outer-icon"
                            append-outer-icon="mdi-delete"
                            v-on:click:append-outer="removeRow(index)"
                            :suffix="getFileExtension(row.file_type)"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr v-if="actionData.attachments?.length < 5">
                        <td colspan="2" class="pl-0">
                          <v-btn
                            color="cyan white--text"
                            small
                            v-on:click="addAttachmentRow()"
                            class="custom-bold-button"
                            >Add More...</v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirmLoading"
            v-on:click="confirmDialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="confirmSubmit()"
            :disabled="confirmLoading || !formValid"
            :loading="confirmLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </confirm-dialog>
      <send-mail
        :mail-dialog="mailDialog"
        title="Send as Email"
        type="invoice"
        v-on:close="mailDialog = false"
        :type-id="invoiceId"
        v-on:success="getInvoice()"
      ></send-mail>
      <CreatePaymentTemplate
        v-if="paymentDialog"
        :detail="dbInvoice"
        :payment-dialog="paymentDialog"
        v-on:close="paymentDialog = false"
        v-on:success="getInvoice()"
      ></CreatePaymentTemplate>
      <CreatePaymentCreditNoteTemplate
        v-if="paymentCreditNoteDialog"
        :detail="dbInvoice"
        :credit-dialog="paymentCreditNoteDialog"
        v-on:close="paymentCreditNoteDialog = false"
        v-on:success="getInvoice()"
      ></CreatePaymentCreditNoteTemplate>
      <DeleteDialog :delete-dialog="deleteInvoiceDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3
            class="font-weight-500 delete-text"
            style="font-size: 20px !important"
          >
            Are you sure you want to delete
            <span class="font-weight-700">{{ dbInvoice?.barcode }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteInvoiceLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteInvoiceDialog = false"
            >No! Cancel</v-btn
          >
          <v-btn
            :disabled="deleteInvoiceLoading"
            :loading="deleteInvoiceLoading"
            color="red lighten-1 white--text"
            v-on:click="deleteInvoiceConfirm()"
            class="mx-2 custom-grey-border custom-bold-button"
            >Yes! Delete</v-btn
          >
        </template>
      </DeleteDialog>
    </template>
  </DetailTemplate>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreatePaymentTemplate from "@/view/pages/invoice/Create-Payment.vue";
import CreatePaymentCreditNoteTemplate from "@/view/pages/invoice/Create-Payment-Credit-Note.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import {
  UPLOAD,
  PATCH,
  GET,
  DELETE,
} from "@/core/services/store/request.module";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";
import LineItemViewV2 from "@/view/components/VisitLineItem.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import SendMail from "@/view/pages/partials/Send-Mail";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import InternalPayment from "@/view/pages/partials/Internal-Payment.vue";
import InternalPaymentCredit from "@/view/pages/partials/Internal-Payment-Credit.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import VisitInternalAll from "@/view/pages/partials/Detail/VisitInternalAll.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import Barcode from "@/view/pages/partials/Barcode.vue";

/*import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
  import moment from "moment-timezone";
  
  moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);*/

export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formLoading: false,
      formValid: true,
      tab: null,
      invoiceTab: "overview",
      deleteInvoiceLoading: false,
      deleteInvoiceDialog: false,
      dbInvoice: {},
      invoiceId: 0,
      invoiceLoading: false,
      paymentDialog: false,
      paymentCreditNoteDialog: false,
      mailDialog: false,
      pageLoaded: false,
      confirmDialog: false,
      confirmLoading: false,
      confirmType: null,
      deleteInvoiceBarcode: null,
      actionData: {
        remark: null,
        status: null,
        uuid: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    deleteInvoiceConfirm() {
      this.deleteInvoiceLoading = true;
      this.$store
        .dispatch(DELETE, { url: `invoice/${this.dbInvoice?.id}` })
        .then(() => {
          this.deleteInvoiceDialog = false;
          this.$router.push(this.getDefaultRoute("invoice"));
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteInvoiceLoading = false;
        });
    },
    routeToEnquiry() {
      this.$router.push(
        this.getDefaultRoute("enquiry.detail", {
          params: { id: this.dbInvoice?.enquiry },
        })
      );
    },
    routeToQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: { id: this.dbInvoice?.quotation },
        })
      );
    },
    routeToVisit() {
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: { id: this.dbInvoice?.visit_relation?.id },
        })
      );
    },
    confirmSubmit() {
      /*  const validateStatus = this.$refs.invoiceStausForm.validate();
       */
      const formErrors = this.validateForm(this.$refs.invoiceStausForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      /*  if(!validateStatus){
        return false;
      }   */
      switch (this.confirmType) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_bad_debt":
          this.updateStatus("mark_as_bad_debt");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_send":
          this.updateStatus("mark_as_send");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
        case "cancel-series":
          this.updateStatus("cancel-series");
          break;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData.attachments.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      this.confirmLoading = true;

      this.actionData.status = this.confirmType;
      this.actionData.uuid = this.dbInvoice?.recurring_batch ?? null;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "invoice/" + this.invoiceId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getInvoice();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData.attachments.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      } else if (this.confirmType == "mark_as_bad_debt") {
        return "Mark as Bad Debt";
      } else if (this.confirmType == "cancel-series") {
        return "Cancel Series";
      }
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editInvoice();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "payment":
          this.paymentDialog = true;
          break;
        case "credit-note":
          this.paymentCreditNoteDialog = true;
          break;
        case "mark_as_closed":
          this.confirmDialog = true;
          this.confirmType = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirmDialog = true;
          this.confirmType = "mark_as_cancelled";
          break;
        case "cancel-series":
          this.confirmDialog = true;
          this.confirmType = "cancel-series";
          break;
        case "mark_as_bad_debt":
          this.confirmDialog = true;
          this.confirmType = "mark_as_bad_debt";
          break;
        case "send_as_email":
          this.mailDialog = true;
          break;
        case "mark_as_pending":
          this.confirmDialog = true;
          this.confirmType = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirmDialog = true;
          this.confirmType = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirmDialog = true;
          this.confirmType = "mark_as_accepted";
          break;
        case "mark_as_approved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_approved";
          break;
        case "mark_as_send":
          this.confirmDialog = true;
          this.confirmType = "mark_as_send";
          break;
        case "mark_as_delete":
          this.deleteInvoiceDialog = true;
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    editInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.update", {
          query: { invoice: this.invoiceId, edit: this.invoiceId },
        })
      );
    },
    routeToJob() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: { invoice: this.invoiceId },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { duplicate: this.invoiceId },
        })
      );
    },
    routeToCustomer() {
      //
    },
    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}invoice-v1/${this.invoiceId}/pdf?token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}invoice-v1/${this.invoiceId}/pdf?token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getInvoice() {
      this.$store
        .dispatch(GET, { url: "invoice-v1/" + this.invoiceId })
        .then(({ data }) => {
          this.dbInvoice = data;

          this.$store.dispatch(SET_VIEW_CALCULATION, {
            ctx_discount_label: this.dbInvoice?.ctx_discount_label ?? null,
            ctx_discount_value: this.dbInvoice?.ctx_discount_value ?? 0,
            ctx_discount: this.dbInvoice?.ctx_discount ?? 0,
            ctx_discount_type: this.dbInvoice?.ctx_discount_type ?? 1,
            sub_total: this.dbInvoice?.sub_total ?? 1,
            discount_type: this.dbInvoice?.discount_type ?? 1,
            discount_value: this.dbInvoice?.discount_value ?? 0,
            discount_amount: this.dbInvoice?.discount_amount ?? 0,
            tax_active: this.dbInvoice?.tax_active ?? false,
            tax_value: this.dbInvoice?.tax_value ?? 0,
            tax_amount: this.dbInvoice?.tax_amount ?? 0,
            adjustment: this.dbInvoice?.adjustment ?? 0,
            total: this.dbInvoice?.total ?? 0,
            show_price: true,
            discount_level: this.dbInvoice?.discount_level ?? "transaction",
          });

          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Invoice", route: "invoice" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
          this.detail_key = Number(new Date());
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.invoiceId = this.lodash.toSafeInteger(this.$route.params.id);
    this.invoiceTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getInvoice();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "line-item-view": LineItemViewV2,
    "internal-history-detail": InternalHistoryDetail,
    "confirm-dialog": Dialog,
    "send-mail": SendMail,
    DetailTemplate,
    CustomStatus,
    CreatePaymentTemplate,
    CreatePaymentCreditNoteTemplate,
    InternalPayment,
    InternalPaymentCredit,
    DeleteDialog,
    Barcode,
    VisitInternalAll,
    AdditionalDocuments,
  },
};
</script>
