<template>
  <div class="internal-payment-listing">
    <v-layout class="my-2">
      <v-flex md4>
        <v-text-field
          v-model.trim="search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          label="Search"
          hide-details
          solo
          :loading="paymentLoading"
          :disabled="paymentLoading"
          color="cyan"
          @keydown.enter="getPayments()"
          @keydown.tab="getPayments()"
        ></v-text-field>
      </v-flex>
      <v-flex md4>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              depressed
              :disabled="paymentLoading"
              v-on:click="getPayments()"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              v-bind="attrs"
              v-on="on"
              class="ml-2 custom-bold-button white--text"
              color="cyan"
              tile
              :disabled="paymentLoading"
              v-on:click="getPayments()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Reload</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <ListingTable :columnCount="6" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Payment #</th>
            <th class="simple-table-th">Date</th>
            <th class="simple-table-th">Customer Info</th>
            <th class="simple-table-th">Invoice</th>
            <th class="simple-table-th">Payment Mode</th>
            <th class="simple-table-th">Amount</th>
            <th class="simple-table-th">Created Time</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(row, index) in rowData"
              :key="index"
              v-on:click="routeToInvoice(row)"
              :class="{ 'orange lighten-5': index % 2 }"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
                <div class="mt-1">
                  <template v-if="row.status == 3">
                    <v-chip color="orange" text-color="white" small label>
                      PARTIALLY PAID
                    </v-chip>
                  </template>
                  <template v-if="row.status == 4">
                    <v-chip color="green" text-color="white" small label>
                      FULLY PAID
                    </v-chip>
                  </template>
                </div>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.payment_date">
                  <b>Payment: </b>
                  {{ formatDate(row.payment_date) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.due_date">
                  <b>Invoice Due: </b>
                  {{ formatDate(row.invoice.due_date) }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.sent_date">
                  <b>Invoice Sent: </b>
                  {{ formatDate(row.invoice.sent_date) }}
                </p>
              </td>
              <td class="simple-table-td">
                <div class="payment-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b>
                    {{ row.billing_contact_person.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b>
                    {{ row.customer.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Email: </b>
                    <template v-if="row?.billing_contact_person?.primary_email">
                      {{ row.billing_contact_person.primary_email }}</template
                    >
                    <template v-else>
                      <em class="text-muted"> no email</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Phone: </b>
                    <template v-if="row?.billing_contact_person?.primary_phone">
                      {{ row.billing_contact_person.primary_phone }}</template
                    >
                    <template v-else>
                      <em class="text-muted"> no phone number</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="payment-listing-invoice" v-on:click.prevent>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <Barcode
                      route="invoice.detail"
                      :id="row.invoice.id"
                      :barcode="row.invoice.barcode"
                    ></Barcode>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="payment-listing-mode">
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ row.payment_mode && row.payment_mode.text }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="payment-listing-amount">
                  <!--  <p class="m-0 custom-nowrap-ellipsis">
                                        <b>Pending: </b>
                                        {{
                    $accountingJS.formatMoney(
                        row.invoice.pending_amount
                    )
                }}
                                    </p> -->
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Received: </b>
                    {{ $accountingJS.formatMoney(row.amount) }}
                    <em class="text--secondary d-block" v-if="row.status == 4"
                      >( Partially Paid )</em
                    >
                    <em
                      class="text--secondary d-block"
                      v-else-if="row.status == 3"
                      >( Paid )</em
                    >
                    <em class="text--secondary d-block" v-else>( Unpaid )</em>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no payment at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="paymentLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </div>
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
/*   import CustomStatus from "@/view/pages/partials/CustomStatus.vue"; */
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      paymentLoading: false,
      rowData: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.$nextTick(() => {
        this.getPayments();
      });
    },
  },
  methods: {
    clearFilter() {
      //
    },
    getPayments() {
      const data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        type: this.type,
        type_id: this.typeId,
      };

      if (this.paymentLoading) {
        return false;
      }

      this.paymentLoading = true;

      this.$store
        .dispatch(QUERY, { url: "payment", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
    routeToInvoice(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("payment.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  mounted() {
    this.getPayments();
  },
  components: {
    Barcode,
    TableActivity,
    /*  CustomStatus, */
    ListingFooter,
    ListingTable,
  },
};
</script>
