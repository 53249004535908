<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          {{ transactionDetail?.product }} :-{{ transactionDetail?.barcode }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 100vh; position: relative"
          >
            <v-form
              ref="serialFormRff"
              v-model.trim="serialFormRff"
              lazy-validation
            >
              <v-row class="p-0" style="border-bottom: 1px solid lightgray">
                <v-col
                  md="9"
                  style="
                    margin-bottom: -1px !important;
                    margin-top: -10px !important;
                  "
                >
                  <label
                    for=""
                    class="font-weight-600 m-0"
                    style="font-size: 18px; color: #24326d !important"
                  >
                    Total Available :
                    {{ transactionDetail.serialize_number?.length }}</label
                  >
                </v-col>
                <v-col
                  md="3"
                  style="
                    margin-bottom: -1px !important;
                    margin-top: -10px !important;
                  "
                >
                  <label
                    for=""
                    class="font-weight-600 m-0"
                    style="font-size: 18px; color: #24326d !important"
                  >
                    Quantity :
                    {{ transactionDetail.serialize_number?.length }} /
                    {{ transactionDetail.quantity }}</label
                  >
                </v-col>
              </v-row>
              <v-row v-if="transactionDetail.serialize_number?.length > 0">
                <v-col
                  lg="2"
                  md="2"
                  v-for="(row, rowindex) in transactionDetail.serialize_number"
                  :key="rowindex"
                  sty
                >
                  <div class="d-flex">
                    <v-chip-group multiple active-class="">
                      <v-chip class="customChipChild" label>
                        {{ row?.product_serialize_number?.text }}
                      </v-chip>
                    </v-chip-group>
                    <div class="mt-3">
                      <showBarcode
                        :barcodeUrl="
                          row?.product_serialize_number?.barcode_image
                        "
                        title="Barcode"
                        :barcode-text="row?.product_serialize_number?.text"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-content-center mt-2 mb-2" v-else>
                <div>
                  <p class="m-0 row-not-found font-weight-500 font-size-16">
                    <img
                      height="30"
                      width="35"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no serial number at the moment.
                  </p>
                </div>
              </v-row>
            </v-form>
          </perfect-scrollbar>
          <v-divider class="my-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="warrant-manage-action">
              <v-btn
                depressed
                :disabled="serialLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="$emit('close:dialog')"
                >Close
              </v-btn>
            </div>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}

.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import showBarcode from "@/view/components/showBarcode.vue";

export default {
  name: "create-or-update-warranty",
  mixins: [CommonMixin],
  props: {
    type: {
      type: String,
      default: null,
    },
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    transactionDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    showBarcode,
  },
  data() {
    return {
      search: null,
      formValid: true,
      serialFormRff: true,
      pageLoading: false,
      serialLoading: false,
      enities: [],
      preArray: [],
    };
  },
  methods: {},
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
